import React from "react";
import { Button } from "./Button";
import cross from "../assests/img/crossIcon.svg";

interface IModal {
  title: string;
  subTitle: string;
  content: React.ReactNode;
  actionText: string;
  onClose: () => void;
  action: (data?: any) => void;
}

export const Modal: React.FC<IModal> = ({
  title,
  subTitle,
  content,
  actionText,
  action,
  onClose,
}) => {
  return (
    <div className="absolute top-0 left-0 w-full h-full z-10 flex items-center justify-center bg-palleteBlack/50">
      <div className="rounded-2xl bg-palleteWhite p-8 max-w-xl w-full">
        <div className=" flex justify-between items-center mb-2">
          <h2 className="font-semibold text-2xl">{title}</h2>
          <Button actionIcon={cross} action={() => onClose()} bgColor="bg-none"/>
        </div>
        <h4 className="font-medium text-base text-palleteGray mb-11">{subTitle}</h4>
        <div className="mb-11">
        {content}
        </div>
        <div className="flex w-full justify-end">
        <Button actionText={actionText} action={action} />
        </div>
      </div>
    </div>
  );
};
