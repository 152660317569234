import React from 'react'

interface IContainer {
    children?: React.ReactNode
}

export const Container:React.FC<IContainer> = ({children}) => {
  return (
    <div className='p-6 w-full bg-palleteWhite rounded-xl shadow-3xl sm:pt-12 sm:pb-12 sm:pr-10 sm:pl-10'>
        {children}
    </div>
  )
}

