import React from "react";
import { FormProvider, useFieldArray, useFormContext } from "react-hook-form";
import { QuestionsListItem } from "./QuestionsListItem";
import { Button } from "../../components/Button";
import plus from "../../assests/img/plusIcon.svg";
import { IQuizCreate } from "./QuizCreate";

interface IQuestionsList {}

export const QuestionsList: React.FC<IQuestionsList> = () => {
  const form = useFormContext<IQuizCreate>();

  const { control, watch } = form;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });
  const watchFieldArray = watch("questions");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const handleAddQuestion = () => {
    append({
      questionName: "",
      answerIndex: 0,
      answerOptions: [],
    });
  };

  const handleDeleteQuestion = (index: number) => {
    remove(index);
  };

  return (
    <FormProvider {...form}>
      <div className="max-w-6xl mx-auto">
        {controlledFields.map((question, index) => (
          <QuestionsListItem
            index={index}
            handleDeleteQuestion={() => handleDeleteQuestion(index)}
            asnwerOptions={question?.answerOptions}
            key={index}
          />
        ))}
        <Button
          actionIcon={plus}
          actionText="Добавить вопрос"
          isLabel
          action={() => handleAddQuestion()}
        />
      </div>
    </FormProvider>
  );
};
