import React from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import { QuestionsList } from "./QuestionsList";
import { IQuizCreate } from "./QuizCreate";

interface IAddQuestions {

}

interface IQuestions {
  name: string;
  options: any[];
}

export interface IQuestionsForm {
  questions: IQuestions[];
}

export const AddQuestions: React.FC<IAddQuestions> = () => {
  const form = useFormContext<IQuizCreate>();
  
  return (
    <FormProvider {...form}>
      <QuestionsList />
    </FormProvider>
  );
};
