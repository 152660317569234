import React, { useMemo } from "react";
import { Modal } from "../../components/Modal";
import { Button } from "../../components/Button";
import copy from "../../assests/img/copyIcon.svg";

interface IQuizLinkModal {
  isOpen: boolean;
  onClose: () => void;
}

export const QuizLinkModal: React.FC<IQuizLinkModal> = ({
  isOpen,
  onClose,
}) => {
  const link = 'https://quiz/655650ec6e98dcafb3dd281f'
  const content = useMemo(
    () => (
      <>
        <p className="mb-11">{link}</p>
        <Button
          actionIcon={copy}
          actionText="Скопировать"
          bgColor="bg-none"
          isLabel
          labelFontWeight={'font-semibold'}
          labelColor="text-palleteGreen"
          action={() => {navigator.clipboard.writeText(link)}}
        />
      </>
    ),
    []
  );
  return (
    isOpen ? (
      <Modal
        title={"Ссылка на квиз"}
        subTitle={"Скопируйте ссылку ниже и разместите на своем сайте"}
        content={content}
        actionText={"Готово"}
        action={onClose}
        onClose={onClose}
      />
    ) : null
  );
};
