import React, { useCallback, useState } from "react";
import { Input } from "../../components/Input";
import { useFormContext } from "react-hook-form";
import { Radio } from "../../components/Radio";
import { Button } from "../../components/Button";
import trash from "../../assests/img/trashIcon.svg";
import { Toggle } from "../../components/Toggle";
import { IQuizCreate } from "./QuizCreate";

export interface IQuestionsListItem {
  handleDeleteQuestion: (index: number) => void;
  index: number;
  asnwerOptions: any[];
}

export const QuestionsListItem: React.FC<IQuestionsListItem> = ({
  handleDeleteQuestion,
  index,
}) => {
  const form = useFormContext<IQuizCreate>();
  const {
    register,
    formState: { errors },
  } = form;
  const [isShowHints, setIsShowHints] = useState<boolean>(false);

  const getCustomError = useCallback(
    (radioError: boolean, inputError: boolean) => {
      let errorText;
      if (radioError) {
        errorText = "Выберите правильный ответ";
      }
      if (inputError) {
        errorText = "Заполните поле";
      }
      if (radioError && inputError) {
        errorText = "Выберите правильный ответ и заполните поле";
      }
      return <span className="text-palleteRed text-xs mt-1">{errorText}</span>;
    },
    []
  );

  return (
    <div className="bg-palleteWhite rounded-2xl px-6 py-5 w-full mb-8">
      <div className="flex items-start justify-between">
        <Input
          labelText={`Вопрос ${index + 1}`}
          placeholder="Напишите вопрос"
          register={register}
          required
          type="text"
          name={`questions.${index}.questionName`}
          gap={24}
          width={48}
          error={errors?.questions?.[index]?.questionName}
        />
        {index !== 0 && (
          <Button
            actionIcon={trash}
            action={handleDeleteQuestion}
            bgColor="bg-none"
          />
        )}
      </div>
      <div className="flex justify-between gap-6">
        <div className="w-full">
          <p className="mb-3">Впишите варианты ответа и отметьте правильный</p>
          <div>
            <div className="mb-4">
              <div className="flex items-center">
                <Radio
                  value={0}
                  id={`isAnswer${index}0`}
                  register={register}
                  fieldName={`questions.${index}.answerIndex`}
                  isRequired
                  noMargin
                />
                <Input
                  placeholder={"Вариант ответа"}
                  type={"string"}
                  required={true}
                  gap={0}
                  name={`questions.${index}.answerOptions.${0}.answerText`}
                  register={register}
                />
              </div>
              {getCustomError(
                !!errors?.questions?.[index]?.answerIndex,
                !!errors?.questions?.[index]?.answerOptions?.[0]?.answerText
              )}
            </div>
            <div className="mb-4">
              <div className="flex items-center">
                <Radio
                  value={1}
                  id={`isAnswer${index}1`}
                  register={register}
                  fieldName={`questions.${index}.answerIndex`}
                  isRequired
                  noMargin
                />
                <Input
                  placeholder={"Вариант ответа"}
                  type={"string"}
                  required={true}
                  gap={0}
                  name={`questions.${index}.answerOptions.${1}.answerText`}
                  register={register}
                />
              </div>
              {getCustomError(
                !!errors?.questions?.[index]?.answerIndex,
                !!errors?.questions?.[index]?.answerOptions?.[1]?.answerText
              )}
            </div>
            <div className="mb-4">
              <div className="flex items-center">
                <Radio
                  value={2}
                  id={`isAnswer${index}2`}
                  register={register}
                  fieldName={`questions.${index}.answerIndex`}
                  isRequired
                  noMargin
                />
                <Input
                  placeholder={"Вариант ответа"}
                  type={"string"}
                  required={true}
                  gap={0}
                  name={`questions.${index}.answerOptions.${2}.answerText`}
                  register={register}
                />
              </div>
              {getCustomError(
                !!errors?.questions?.[index]?.answerIndex,
                !!errors?.questions?.[index]?.answerOptions?.[2]?.answerText
              )}
            </div>

            <div className="flex items-center">
              <Radio
                value={3}
                id={`isAnswer${index}3`}
                register={register}
                fieldName={`questions.${index}.answerIndex`}
                isRequired
                noMargin
              />
              <Input
                placeholder={"Вариант ответа"}
                type={"string"}
                required={true}
                gap={0}
                name={`questions.${index}.answerOptions.${3}.answerText`}
                register={register}
              />
            </div>
            {getCustomError(
              !!errors?.questions?.[index]?.answerIndex,
              !!errors?.questions?.[index]?.answerOptions?.[3]?.answerText
            )}
          </div>
        </div>

        <div className="w-full">
          <Toggle
            toggleText="Комментарий к ответу"
            setIsToggle={setIsShowHints}
            index={index}
          />
          {isShowHints && (
            <div className="mt-3">
              <Input
                placeholder={"Комментарий"}
                type={"string"}
                required={isShowHints}
                gap={16}
                name={`questions.${index}.answerOptions.${0}.answerHint`}
                register={register}
                error={
                  errors?.questions?.[index]?.answerOptions?.[0]?.answerHint
                }
              />
              <Input
                placeholder={"Комментарий"}
                type={"string"}
                required={isShowHints}
                gap={16}
                name={`questions.${index}.answerOptions.${1}.answerHint`}
                register={register}
                error={
                  errors?.questions?.[index]?.answerOptions?.[1]?.answerHint
                }
              />
              <Input
                placeholder={"Комментарий"}
                type={"string"}
                required={isShowHints}
                gap={16}
                name={`questions.${index}.answerOptions.${2}.answerHint`}
                register={register}
                error={
                  errors?.questions?.[index]?.answerOptions?.[2]?.answerHint
                }
              />
              <Input
                placeholder={"Комментарий"}
                type={"string"}
                required={isShowHints}
                gap={16}
                name={`questions.${index}.answerOptions.${3}.answerHint`}
                register={register}
                error={
                  errors?.questions?.[index]?.answerOptions?.[3]?.answerHint
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
