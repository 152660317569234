import { Quiz } from '../modules/Quiz/Quiz'
import {useEffect, useState} from "react";
import {getQuizData} from "../api/quiz/quiz";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "../components/Button";
import {IQuiz} from "../api/quiz/types";
import {ROUTE_PATH} from "../Routes/consts";
import {AxiosError} from "axios";

export const QuizPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [quizData, setQuizData] = useState<IQuiz | null>(null)
    const [resStatusCode, setResStatusCode] = useState<number | null>(null)

    const {quizId} = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        const fetchQuestionsData = async () => {
            try {
                setIsLoading(true)
                const response = await getQuizData(quizId || '');
                setQuizData(response.data.data)
                setIsLoaded(true)
            } catch (err: any) {
                setResStatusCode(err.response.status)
                setIsFailed(true)
            }
            finally {
                setIsLoading(false)
            }
        }

        fetchQuestionsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (resStatusCode && resStatusCode === 403) {
            navigate(ROUTE_PATH.FINAL)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resStatusCode]);

    const handleReload = () => {
        setIsFailed(false)
        setIsLoading(true)
        getQuizData(quizId || '').then(res => {
            setQuizData(res.data.data)
            setIsLoaded(true)
        }).catch((err: AxiosError) => {
            err.response && setResStatusCode(err.response.status)
            setIsFailed(true)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const renderBody = () => {
        if (isFailed) {
            return (
                <div className="flex flex-col items-center w-full">
                    <p className="text-palleteBlack font-semibold mb-10 text-center">При загрузке вопросов произошла ошибка.</p>
                    <Button actionText={'Перезагрузить'} action={handleReload} disabled={isLoading} />
                </div>
            )
        }

        if (isLoaded) {
            return quizData && <Quiz quizData={quizData} />
        }
    }

    return (
        <>
            {renderBody()}
        </>
    )
}
