import React, { useMemo } from "react";

interface IQuizCreateFoldersItem {
  folder: any;
  index: number;
  activeFolderIndex: number;
  setActiveFolderIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const QuizCreateFoldersItem: React.FC<IQuizCreateFoldersItem> = ({
  folder,
  index,
  activeFolderIndex,
  setActiveFolderIndex,
}) => {
  const isActive = useMemo(
    () => activeFolderIndex === index,
    [activeFolderIndex, index]
  );

  return (
    <div>
      <div
        className={`flex p-3 ${
          isActive ? "bg-palleteWhite" : "bg-none"
        }  gap-3 items-center rounded-xl cursor-pointer`}
        onClick={() => setActiveFolderIndex(index)}
      >
        <div
          className={`${folder?.error ? 'bg-palleteRed' : 'bg-palleteGreen'} px-3 py-1 font-semibold rounded-lg text-palleteWhite`}
        >
          {index + 1}
        </div>
        <div className="font-semibold">{folder?.name}</div>
      </div>
    </div>
  );
};
