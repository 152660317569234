import { SignUp } from '../modules/SignUp/SignUp'
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {ROUTE_PATH} from "../Routes/consts";

export const SignUpPage = () => {
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        if (location.state?.from !== ROUTE_PATH.QUIZ) {
            navigate(ROUTE_PATH.QUIZ)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <SignUp gID={location.state.gID} selectedOption={location.state.selectedOption}/>
}
