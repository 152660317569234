import React from "react";

interface IToggle {
  toggleText?: string;
  setIsToggle: (value: boolean) => void;
  index?: number
}

export const Toggle: React.FC<IToggle> = ({ toggleText, setIsToggle, index }) => {
  return (
    <>
      <label
        htmlFor={`toggle-${index}`}
        className="inline-flex relative items-center cursor-pointer select-none"
      >
        <input type="checkbox" id={`toggle-${index}`} className="sr-only peer" onChange={e => setIsToggle(e.target.checked)}/>
        <div
          className="
            w-[2rem] h-[1rem] bg-palleteGray
            after:content-['']
            after:absolute 
            after:top-[0.345rem]
            after:left-[0.1rem]
            peer-checked:after:left-[1.05rem]
            after:border-palleteGray
            after:bg-palleteWhite
            after:border
            after:rounded-full
            after:h-3
            after:w-3
            after:transition-all
            peer-checked:bg-palleteGreen
            peer-checked:after:border-palleteGreen
            rounded-full
        "
        >
          {" "}
        </div>
        {toggleText && <span className="ml-2">{toggleText}</span>}
      </label>
    </>
  );
};
