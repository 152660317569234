import { useState } from "react";
import { Input } from "../../components/Input";
import { FormProvider, useForm } from "react-hook-form";
import { QuizCreateFolders } from "./QuizCreateFolders";
import { Button } from "../../components/Button";
import { QuizLinkModal } from "./QuizLinkModal";

interface IAnswerOption {
  answerText: string;
  answerHint?: string;
}

interface IQuestions {
  questionName: string;
  answerOptions: IAnswerOption[];
  answerIndex: number;
}

export interface IQuizCreate {
  quizName: string;
  regPeriod: "after" | "before";
  questions: IQuestions[];
}

export const QuizCreate = () => {
  const form = useForm<IQuizCreate>({
    defaultValues: {
      questions: [
        {
          questionName: "",
          answerOptions: [],
        },
      ],
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const [isQuizLinkModalOpen, setIsQuizLinkModalOpen] =
    useState<boolean>(false);

  const onOpenQuizLinkModal = () => setIsQuizLinkModalOpen(true);
  const onHandleCreateQuiz = (data: IQuizCreate) => {
    console.log(data);
    onOpenQuizLinkModal();
  };

  return (
    <div className=" flex flex-col justify-between lg:flex-row">
      <div className="w-full">
        <h1 className="text-2xl font-bold mb-6 sm:text-4xl">
          Создание викторины
        </h1>
        <div className="flex justify-between items-center w-full">
          <Input
            labelText="Название"
            placeholder="Напишите название викторины"
            register={register}
            error={errors.quizName}
            required={true}
            type="text"
            name="quizName"
            gap={24}
            width={50}
            isBold
          />
          <Button
            actionText={"Создать ссылку на квиз"}
            action={handleSubmit(onHandleCreateQuiz)}
          />
        </div>
        <FormProvider {...form}>
          <QuizCreateFolders />
        </FormProvider>
      </div>
      <QuizLinkModal
        isOpen={isQuizLinkModalOpen}
        onClose={() => setIsQuizLinkModalOpen(false)}
      />
    </div>
  );
};
