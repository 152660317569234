import React, { HTMLProps } from "react";

interface IButton {
  actionText?: string;
  actionIcon?: string;
  isSubmit?: boolean;
  action?: (data?: any) => void;
  disabled?: boolean;
  marginTop?: number;
  marginBottom?: number;
  width?: number;
  bgColor?: HTMLProps<HTMLElement>["className"];
  labelColor?: HTMLProps<HTMLElement>["className"];
  labelFontWeight?: HTMLProps<HTMLElement>["className"];
  isLabel?: boolean;
}

export const Button: React.FC<IButton> = ({
  actionText,
  isSubmit,
  action,
  disabled,
  marginTop,
  marginBottom,
  width,
  actionIcon,
  bgColor,
  labelColor,
  labelFontWeight,
  isLabel,
}) => {
  return (
    <>
      <button
        style={{
          marginTop: `${marginTop}px`,
          marginBottom: `${marginBottom}px`,
          width: `${width}rem`,
        }}
        className={`${
          disabled ? "opacity-60" : "opacity-100"
        }  text-palleteWhite transition hover:scale-105`}
        onClick={action && (() => action())}
        disabled={disabled}
        type={isSubmit ? "submit" : "button"}
      >
        <div className="flex items-center">
          <div className={`${bgColor ?? "bg-palleteGreen"} p-4 rounded-2xl`}>
            {actionText && !isLabel && actionText}
            {actionIcon && <img src={actionIcon} alt="actionIcon" />}
          </div>
          {isLabel && (
            <p className={`${labelColor ?? 'text-palleteBlack'} ${labelFontWeight ?? "font-normal"} ml-2`}>{actionText}</p>
          )}
        </div>
      </button>
    </>
  );
};
