import React from "react";
import { UseFormRegister, FieldError } from "react-hook-form";

interface IInput {
  placeholder: string
  type: string
  register?: UseFormRegister<any>
  required: boolean
  name?: string
  labelText?: string;
  error?: FieldError
  errorMessage?: string
  gap?: number
  width?: number
  isBold?: boolean
}

export const Input: React.FC<IInput> = ({
  labelText,
  placeholder,
  type,
  register,
  required,
  name,
  error,
  errorMessage,
  gap,
  width,
  isBold
}) => {
  return (
    <div
      style={{
        marginBottom: `${gap}px`,
        width: `${width}rem`,
      }} 
      className={`text-palleteBlack placeholder-palleteGray w-full`}
      >
      {labelText && (<p className={`text-base mb-3 ${isBold ? 'font-semibold' : 'font-normal'}`}>{labelText}</p>)}
      <input 
      type={type}
      placeholder={placeholder}
      className={`border-palleteGray text-sm text-palleteBlack border-[1px] outline-none w-full px-4 py-[14px] rounded-[4px]`}
      {...register && {...register(`${name}`, {required: required})}}
      />
      {error && (<span className='text-palleteRed text-xs'>{errorMessage ? errorMessage : 'Заполните поле'}</span>)}
    </div>
  );
};
