import React, { useState, useEffect } from "react";
import { Container } from "../../components/Container";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../components/Button";
import { Radio } from "../../components/Radio";
import { useNavigate } from "react-router-dom";
import {checkResult} from "../../api/results/results";
import {Ghost} from "../../components/Ghost/Ghost";
import {ROUTE_PATH} from "../../Routes/consts";
import {IQuiz} from "../../api/quiz/types";

export interface IQuizForm {
  answerIndex: number;
}

export interface IServerResponse {
  answerCode: number,
  availabilityCode: number
}

/**
 * Квиз.
 *
 * @prop quizData Данные для вопросов и текстовок квиза.
 */
interface IProps {
  quizData: IQuiz
}

export const Quiz: React.FC<IProps> = ({ quizData }) => {
  const { name, questionsList, _id } = quizData;

  const [answerIndex, setAnswerIndex] = useState<number | null>(null);
  const [isLoadingChecking, setIsLoadingChecking] = useState<boolean>(false);
  const [isLoadedChecking, setIsLoadedChecking] = useState<boolean>(false)
  const [serverResponse, setServerResponse] = useState<IServerResponse | null>(null)
  const [timeLeft, setTimeLeft] = useState(10)
  const navigate = useNavigate()

  useEffect(() => {
    if (serverResponse && !serverResponse?.answerCode) {
      const interval = setInterval(() => {
        setTimeLeft(prevState => prevState >= 1 ? prevState - 1 : 0)
      }, 1000)
      return () => {
        clearInterval(interval);
      }
    } else if (serverResponse) {
      navigate(ROUTE_PATH.SIGNUP, {state: {
          from: ROUTE_PATH.QUIZ,
          gID: _id,
          selectedOption: answerIndex
        }})
    }

  }, [serverResponse, navigate, _id, answerIndex])

  useEffect(() => {
    if (timeLeft === 0) {
      navigate(ROUTE_PATH.FINAL)
    }
  }, [timeLeft, navigate])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<IQuizForm>();

  const onSubmit: SubmitHandler<IQuizForm> = ({ answerIndex }) => {
    if (!answerIndex) {
      setError("answerIndex", {
        type: "required",
      });
    } else {
      setAnswerIndex(answerIndex);
      setIsLoadingChecking(true)
      checkResult(_id, answerIndex).then(res => {
        setServerResponse(res.data)
      }).finally(() => {
        setIsLoadingChecking(false)
        setIsLoadedChecking(true)
      })
    }
  };

  const renderButtonLabel = () => {
    if (isLoadingChecking) {
      return 'Загрузка...'
    } else if (serverResponse) {
      return `Далее через ${timeLeft}...`
    } else {
      return 'Ответить'
    }
  }

  const handleRedirect = () => {
    if (!answerIndex) {
      return () => {}
    } else {
      if (serverResponse?.answerCode && serverResponse.answerCode) {
        return () => navigate(ROUTE_PATH.SIGNUP, {state: {
            from: ROUTE_PATH.QUIZ
          }})
      } else {
        return () => navigate(ROUTE_PATH.FINAL)
      }
    }
  }

  return (
    <Container>
      <div>
        <h1 className="text-2xl font-bold mb-6 sm:text-4xl">{name}</h1>
        <p className="text-sm sm:text-xl mb-1">
          Вопрос 1 из 1
        </p>
        <p className="mb-6 text-sm sm:text-sm text-[#939393]">
          Выберите правильный ответ
        </p>
        {questionsList.map((question, idx) => (
            <div key={`${question.questionTitle}-${idx}`}>
              <h3 className="text-xl font-bold mb-[34px] sm:text-2xl">
                {question.questionTitle}
              </h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                {question?.options?.map((option, idx) => (
                    <Radio
                        type={'asnwer'}
                        key={`${option.text}-${idx}`}
                        value={option.index}
                        id={idx.toString()}
                        labelText={option.text}
                        hintText={option.hint}
                        register={register}
                        answer={answerIndex}
                        serverCode={serverResponse?.answerCode}
                        isLoadedAnswer={isLoadedChecking}
                        fieldName="answerIndex"
                    />
                ))}
                {errors.answerIndex && (
                    <span className="text-palleteRed text-xs">
                  {"Необходимо выбрать вариант ответа"}
                </span>
              )}
              <div className="lg:mt-[7rem] mt-[4.5rem] text-center">
                <Button
                  isSubmit={!answerIndex}
                  actionText={renderButtonLabel()}
                  action={handleRedirect()}
                  width={20}
                />
              </div>
            </form>
          </div>
        ))}
      </div>
      {serverResponse && !serverResponse?.answerCode && <Ghost />}
    </Container>
  );
};
