import {AxiosResponse} from "axios";
import axiosInstance from "../index";
import {IUserAnswerRequestBody} from "./types";

export const checkResult = (gID: string, answer: number): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(`/results/check-result/${gID}/${answer}`)
}

export const saveResult = (body: IUserAnswerRequestBody): Promise<AxiosResponse<any>> => {
    return axiosInstance.post('/results/save-result', body)
}
