import { Container } from "../../components/Container";
import { Input } from "../../components/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "./../../components/Button";
import { useNavigate } from "react-router-dom";
import { saveResult } from "../../api/results/results";
import Confetti from "react-confetti";
import image from "../../assests/img/signUpImage.jpg";
import React from "react";
import {ROUTE_PATH} from "../../Routes/consts";

interface SignUpForm {
  name: string;
  email: string;
}

interface IProps {
  gID: string;
  selectedOption: number
}

export const SignUp: React.FC<IProps> = ({gID, selectedOption}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<SignUpForm>();

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<SignUpForm> = (data) => {
    const userData = {
      gID,
      selectedOption,
      ...data
    }
    saveResult(userData).finally(() => navigate(ROUTE_PATH.SUCCESS, {state: {
        fromSignUp: true
      }}));
    reset();
  };

  return (
    <Container>
      <div className="flex justify-between">
        <div className="max-w-[100%] lg:max-w-[50%]">
        <h1 className="text-2xl font-bold mb-10 sm:text-4xl">
          «Знаешь ли ты?»
          </h1>
          <h3 className="text-2xl font-bold mb-10 text-palleteGreen sm:text-4xl">Поздравляем!<br /> Это правильный ответ.</h3>
          <p className="text-palleteBlack font-semibold mb-10">Оставьте Ваши контактные данные для получения приза.</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              labelText="Имя"
              placeholder="Укажите имя"
              register={register}
              error={errors.name}
              required={true}
              type="text"
              name="name"
              gap={24}
              width={20}
            />
            <Input
              labelText="Почта"
              placeholder="example@email.com"
              register={register}
              error={errors.email}
              required={true}
              type="text"
              name="email"
              gap={48}
              width={20}
            />
            <Button isSubmit actionText="Отправить" width={20} />
          </form>
        </div>
        <div className="hidden max-w-[50%] lg:block">
          <img src={image} alt="signUpImage" className="h-full w-full object-cover rounded-2xl" />
        </div>
      </div>
      <Confetti numberOfPieces={1000} recycle={false} />
    </Container>
  );
};
