import React, { useMemo } from "react";
import {
  FieldError,
  UseFormRegister,
} from "react-hook-form";
import clsx from "clsx";
import { Input } from "./Input";

interface IRadio {
  labelText?: string | undefined;
  value: number;
  serverCode?: number;
  hintText?: string | undefined;
  register: UseFormRegister<any>;
  answer?: number | null;
  id?: string;
  isLoadedAnswer?: boolean;
  type?: "simple" | "input" | "asnwer";
  fieldName: string;
  isRequired?: boolean;
  error?: FieldError
  errorMessage?: string
  noMargin?: boolean
}

export const Radio: React.FC<IRadio> = ({
  labelText,
  value,
  serverCode,
  hintText,
  register,
  answer,
  id,
  isLoadedAnswer,
  type,
  fieldName,
  isRequired,
  error,
  errorMessage,
  noMargin,
}) => {
  const inputClassnames = clsx({
    hidden: true,
    radioInput: true,
    properOption: !!serverCode && isLoadedAnswer,
    falcyOption: !serverCode && isLoadedAnswer,
  });

  const radioByType = useMemo(() => {
    switch (type) {
      case "asnwer":
        return (
          <>
            <input
              id={id}
              type="radio"
              className={inputClassnames}
              value={value}
              disabled={!!answer}
              {...register(fieldName, {
                required: isRequired,
              })}
            />
            <label
              htmlFor={id}
              className="flex items-center cursor-pointer w-full"
            >
              <span
                className={`w-[24px] h-[24px] mr-2 rounded-full border  ring-palleteGray`}
              ></span>
              <p className="flex-1">{labelText}</p>
            </label>
            {Number(answer) === Number(value) && isLoadedAnswer && hintText && (
              <span
                className={
                  "answerHint absolute p-3 bg-palleteLightGreen rounded-lg left-0 w-80 ml-0 text-sm translate-y-3/4 z-10 xl:left-[50%] xl:ml-6 xl:translate-y-0"
                }
              >
                {hintText}
              </span>
            )}
          </>
        );
      case "input":
        return (
          <>
            <input
              id={id}
              type="radio"
              className={inputClassnames}
              value={value}
              disabled={!!answer}
              {...register(fieldName, {
                required: isRequired,
              })}
            />
            <label
              htmlFor={id}
              className="flex items-center cursor-pointer w-full"
            >
              <span
                className={`w-[24px] h-[24px] mr-2 rounded-full border border-grey`}
              ></span>
              <Input
                placeholder={"Вариант ответа"}
                type={"string"}
                required={true}
              />
            </label>
          </>
        );
      default:
        return (
          <>
            <input
              id={id}
              type="radio"
              className={inputClassnames}
              value={value}
              disabled={!!answer}
              {...register(fieldName, {
                required: isRequired,
              })}
            />
            <label
              htmlFor={id}
              className="flex items-center cursor-pointer w-full"
            >
              <span
                className={`border-palleteBlack w-[24px] h-[24px] mr-2 rounded-full border`}
              ></span>
              <p className="flex-1">{labelText}</p>
            </label>
          </>
        );
    }
  }, [answer, fieldName, hintText, id, inputClassnames, isLoadedAnswer, isRequired, labelText, register, type, value]);
  return (
    <div key={id} className={`${noMargin ?  "" : "mb-4"}`}>
      <div className="flex items-center relative">
      {radioByType}
      </div>
      {error && (<span className='text-palleteRed text-xs mt-1'>{errorMessage ? errorMessage : 'Необходимо заполнить'}</span>)}
    </div>
  );
};
