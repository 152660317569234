import React, { useState } from "react";
import { QuizCreateFoldersItem } from "./QuizCreateFoldersItem";
import { Radio } from "../../components/Radio";
import {
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { IQuizCreate } from "./QuizCreate";
import { AddQuestions } from "./AddQuestions";

interface IQuizCreateFolders {}

export const QuizCreateFolders: React.FC<IQuizCreateFolders> = () => {
  const form = useFormContext<IQuizCreate>();

  const {
    formState: { errors },
    register,
  } = form;

  const [activeFolderIndex, setActiveFolderIndex] = useState(0);

  const registrationFolderContent = () => {
    return (
      <>
        <p className="mb-4 font-semibold">Когда регистрировать участника?</p>
        <Radio
          value={1}
          id={"before"}
          labelText={"Перед прохождением викторины"}
          register={register}
          fieldName="regPeriod"
          isRequired
          error={errors?.regPeriod}
          errorMessage="Выберите период"
        />
        <Radio
          value={2}
          id={"after"}
          labelText={"После прохождения викторины"}
          register={register}
          fieldName="regPeriod"
          isRequired
          error={errors?.regPeriod}
          errorMessage="Выберите период"
        />
      </>
    );
  };

  const fillingFolderContent = () => {
    return (
        <AddQuestions />
    );
  };

  const foldersContent = [
    {
      name: "Окно регистрации",
      content: registrationFolderContent(),
      error: errors?.regPeriod
    },
    {
      name: "Наполнение",
      content: fillingFolderContent(),
      error: errors?.questions || errors?.quizName
    },
  ];

  return (
    <FormProvider {...form}>
      <div className="flex gap-4 mb-8">
        {foldersContent.map((folder, index) => (
          <QuizCreateFoldersItem
            key={index}
            folder={folder}
            index={index}
            activeFolderIndex={activeFolderIndex}
            setActiveFolderIndex={setActiveFolderIndex}
          />
        ))}
      </div>
      {foldersContent.map((folder, index) => (
        <div className={`${index !== activeFolderIndex ? "hidden" : ""}`} key={index}>
          {folder?.content}
        </div>
      ))}
    </FormProvider>
  );
};
