import { Container } from '../../components/Container'
import React from "react";
import Confetti from "react-confetti";
import desktopImage from "../../assests/img/signUpImage.jpg"
import mobileImage from "../../assests/img/successMobile.png"

/**
 * prop fromSignUp Флаг перехода со страницы регистрации.
 */
interface IProps {
  fromSignUp?: boolean
}
export const Success: React.FC<IProps> = ({fromSignUp}) => {

  return (
    <Container>
      <div className="flex flex-col justify-between lg:flex-row">
        <div className="max-w-[100%] lg:max-w-[50%]">
          <h1 className="text-2xl font-bold mb-10 sm:text-4xl">
            «Знаешь ли ты?»
          </h1>
          {!fromSignUp && <h3 className="text-2xl font-bold mb-10 text-palleteGreen sm:text-4xl">Поздравляем!<br /> Это правильный ответ.</h3>}
          <p className="text-palleteBlack font-semibold">Спасибо за участие!</p>
        </div>
        <div className="max-w-[100%] lg:max-w-[50%] m-auto">
          <img src={desktopImage} alt="successDesktopImage" className="hidden min-h-[614px] h-full w-full object-cover rounded-2xl lg:block" />
          <img src={mobileImage} alt="successMobileImage" className="lg:hidden h-[355px]" />
        </div>
      </div>
      {!fromSignUp && <Confetti numberOfPieces={1000} recycle={false}/>}
    </Container>
  )
}
