import { Success } from '../modules/Success/Success'
import { useLocation } from 'react-router-dom';

export const SuccessPage = () => {
    const location = useLocation();

    return (
        <Success fromSignUp={location.state && location.state.fromSignUp} />
    )
}
