import {createBrowserRouter, Outlet} from "react-router-dom";
import {QuizPage} from "../Pages/QuizPage";
import {SuccessPage} from "../Pages/SuccessPage";
import {FinalPage} from "../Pages/FinalPage";
import {SignUpPage} from "../Pages/SignUpPage";
import {ROUTE_PATH} from "./consts"
import { QuizCreatePage } from "../Pages/QuizCreatePage";
import { Header } from "../components/Header";

const router = createBrowserRouter([
    {
        element: (
            <div className={`bg-[url('./assests/img/generalBg.jpg')] bg-cover`}>
                <div className='sm:max-w-full md:max-w-2xl lg:max-w-3xl xl:max-w-5xl mx-auto min-h-screen flex items-center'>
                    <Outlet />
                </div>
            </div>
        ),
        children: [
            {
                path: ROUTE_PATH.QUIZ,
                element: <QuizPage />,
            },
            {
                path: ROUTE_PATH.SUCCESS,
                element: <SuccessPage  />,
            },
            {
                path: ROUTE_PATH.FINAL,
                element: <FinalPage />,
            },
            {
                path: ROUTE_PATH.SIGNUP,
                element: <SignUpPage />,
            },
        ],
    }, {
        element: (
            <div className="min-h-screen flex flex-col relative">
                <Header />
                <div className='p-8 bg-palleteLightGray grow'>
                    <Outlet />
                </div>
            </div>
        ),
        children: [
            {
                path: ROUTE_PATH.QUIZ_CREATE,
                element: <QuizCreatePage />,
            },
        ],
    },
]);

export default router
