import { Container } from '../../components/Container'
import desktopImage from "../../assests/img/signUpImage.jpg"
import mobileImage from "../../assests/img/successMobile.png"
import React from "react";

export const Final = () => {
  return (
    <Container>
      <div className="flex flex-col justify-between lg:flex-row">
        <div className="max-w-[100%] lg:max-w-[50%]">
          <h1 className="text-2xl font-bold mb-10 sm:text-4xl">
            «Знаешь ли ты?»
          </h1>
          <p className="text-palleteBlack font-semibold">Спасибо за участие!</p>
        </div>
        <div className="max-w-[100%] lg:max-w-[50%] m-auto">
          <img src={desktopImage} alt="successDesktopImage" className="hidden min-h-[614px] h-full w-full object-cover rounded-2xl lg:block" />
          <img src={mobileImage} alt="successMobileImage" className="lg:hidden h-[355px]" />
        </div>
      </div>
    </Container>
  )
}
